<template>
	<swiper
		class="swiper-container"
		:slides-per-view="1"
		:space-between="3"
		:autoplay="{
			delay: 5000,
			disableOnInteraction: false
		}"
		loop
		:pagination="{ clickable: true }"
	>
		<swiper-slide @click="$emit('tap', item)" v-for="(item, index) in list" :key="index" class="swiper_slide">
			<img :src="item.img" alt="" />
		</swiper-slide>
	</swiper>
</template>

<script>
//需要哪个导入哪个
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
//这里导入对应样式
import 'swiper/components/pagination/pagination.scss'
//导入后在SwiperCore.use([])里注册
SwiperCore.use([Pagination, Autoplay])
export default {
	components: { Swiper, SwiperSlide },
	name: 'product',
	data() {
		return {
		}
	},
  props: {
    list: ''
  },
	created() {},
	mounted() {},
	methods: {}
}
</script>

<style lang="scss">
.swiper-container {
	width: 100%;
	height: 100%;
}
.swiper_slide {
	display: flex;
	align-items: center;
	width: 100% !important;
	height: 100%;
	background-color: #eee;
}
.swiper_slide img{
	width: 100%;
	height: 100%;
	pointer-events: none;
	object-fit: cover;
}
.swiper-pagination {
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	border-radius: .3rem;
}
.swiper-pagination-bullet {
	margin: 0 .05rem !important;
	width: .15rem;
	height: .15rem;
	opacity: .5;
	background-color: #000;
	border-radius: 50%;
}
.swiper-pagination-bullet-active {
	opacity: 1;
}
</style>
